<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <v-row class="my-2">
          <v-col cols="12">
            <span
                style="font-weight: bolder; font-size: larger">Download von offenen Vermessungsunterlagen (Open Data) </span>
            <a
                style="color: #3C506E"
                @click="showProductInfo=!showProductInfo">
              <v-icon class="ml-5 mt-n1" small
                      color="#3C506E"
              >mdi-information-outline
              </v-icon>
              Produktinformationen <span v-if="!showProductInfo">anzeigen</span>
              <span v-else>ausblenden</span></a>
          </v-col>
          <v-col cols="12" v-if="showProductInfo">
            <v-card color="#E5EDF6" class="ma-1 mr-6 elevation-3">
              <VListProductInfo/>
            </v-card>

          </v-col>
          <v-col cols="12">
            <div style="font-weight: bolder">Nutzungshinweise</div>
            Eine Haftung für die zur Verfügung gestellten Daten und Dienste wird ausgeschlossen. Dies gilt insbesondere
            für deren Aktualität, Richtigkeit, Verfügbarkeit, Qualität und Vollständigkeit sowie die Kompatibilität und
            Interoperabilität mit den Systemen des Nutzers. Vom Haftungsausschluss ausgenommen sind gesetzliche
            Schadensersatzansprüche für eine Verletzung des Lebens, des Körpers und der Gesundheit sowie die gesetzliche
            Haftung für sonstige Schäden, soweit diese auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung
            beruhen.

            <br><br>Bei den Vermessungsrissen handelt es sich um fachlich zu interpretierende Daten die eine
            qualifizierte und sorgsame Verwendung voraussetzt. Für die Nutzung ist bei Bedarf eine qualifizierte
            Fachkraft hinzuzuziehen.

            <br><br>Die Vermessungsrisse und AP Karten dürfen nur unverändert weitergegeben und veröffentlicht werden.
            Eigene Vervielfältigungen dürfen nur angefertigt werden, wenn hierfür die Verantwortung für mögliche
            Abweichungen vom Original übernommen wird und das Dokument darf für eine Weitergabe oder Veröffentlichung
            nur umgestaltet werden, wenn die Umgestaltung und die hierfür verantwortliche Stelle eindeutig kenntlich
            gemacht werden. Bei Veröffentlichungen ist ein Quellenvermerk erforderlich, soweit die Quelle nicht
            unmittelbar aus dem Dokument ersichtlich ist. <br>Quelle: Geobasisdaten NRW (<i>&lt;Jahr&gt;</i>), Datenlizenz
            Deutschland - Namensnennung - Version 2.0 (dl-de/by-2-0)
            <br><br>
            Für die ALKIS Bestandsdaten gelten die durch den IT-Planungsrat im Datenportal für Deutschland (GovData)
            veröffentlichten einheitlichen Lizenzbedingungen „Datenlizenz Deutschland – Zero“
            (<a href="https://www.govdata.de/dl-de/zero-2-0" target="_blank">https://www.govdata.de/dl-de/zero-2-0</a>).
            Jede Nutzung ist ohne Einschränkungen oder Bedingungen zulässig.
          </v-col>
          <v-col cols="12">
            <div style="font-weight: bolder">Datenschutzhinweise</div>
            Zum Zwecke der Datenbereitstellung ist die Angabe einer E-Mail-Adresse notwendig, an welche der
            Download-Link für den Datenzugriff gesendet wird. Diese E-Mail-Adresse wird ausschließlich für die
            Datenbereitstellung gespeichert und nach längstens 24 Stunden gelöscht.
            <v-icon class="ml-5 mr-1 mt-n1" small
                    color="#3C506E"
            >mdi-information-outline
            </v-icon>
            <router-link style="text-decoration: none;color:#3C506E" to="/datenschutz">Weitere Datenschutzhinweise
            </router-link>
          </v-col>

        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="mb-4" justify="center">
      <v-btn color="green" height="30" dark class="mx-6"
             href="/ASWeb/ASC_URL/GISConnectorRIA.do?PROJECT=VermUnterlagenPortal&USER=gast&PWD=gast&APPLNAME=Bestellklient">
        <v-icon
            left
        >mdi-cube-outline
        </v-icon>
        <span style="font-size: larger">Portal-Anwendung öffnen</span>
      </v-btn>

      <v-btn color="#3C506E" height="30" dark class="mx-6" to="/bestellformular">
        <v-icon
            left

        >mdi-human-handsup
        </v-icon>
        <span style="font-size: larger">Bestellformular ohne Kartennutzung (barrierefreier)</span>
      </v-btn>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <v-row class="my-2">
          <v-col cols="auto" class="ml-2 pr-0">
            <v-icon small
                    color="red"
            >mdi-alert-circle-outline
            </v-icon>
          </v-col>
          <v-col style="color:red">
            Der Download von offenen Vermessungsunterlagen ist <span style="font-weight: bolder">aktuell noch nicht landesweit </span>
            möglich. <br/>Außerdem ist der Umfang der angebotenen Produkte von den einzelnen Katasterbehörden abhängig.
            <div class="mt-2"><a
                style="color: #3C506E"
                @click="showAvailability=!showAvailability"
            >
              <v-icon class="mr-0 mt-n1" small
                      color="#3C506E"
              >mdi-information-outline
              </v-icon>
              Verfügbarkeit <span v-if="!showAvailability">anzeigen</span>
              <span v-else>ausblenden</span></a></div>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
    <InfoMap :messages="messages" typeOfAccess="opendata" v-if="showAvailability" class="mb-2"/>

  </v-container>
</template>


<script>
import axios from "axios";
import InfoMap from "@/components/InfoMap";
import VListProductInfo from "@/components/VListProductInfo";
import {API} from '@/config';

export default {

  components: {
    InfoMap,
    VListProductInfo
  },
  data() {
    return {

      showProductInfo: false,
      showAvailability: false,
      messages: [],
    }
  },
  methods: {
    getMessages() {
      axios.post(`${API.url}${API.getMessages}`).then((response) => {
        this.messages = response.data.features;
      })

    },
  },
  mounted() {
    this.getMessages();
  },
}
</script>
